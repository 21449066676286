import { Router } from '@angular/router';
import { ListResponse } from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';

type Constructor<T> = new (...args: any[]) => T;
// Return type too complex. No use in replicating it here.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PaginationMixin<T extends Constructor<any>>(Base: T) {
  return class extends Base {
    private page = 0;
    private pageMax = 0; // Highest page that has been loaded.
    private itemsPerPage = 10;

    public isLoading = false;
    public error?: Error;

    public getPage(): number {
      return this.page;
    }

    public hasNextPage(): boolean {
      return (
        (this.pageMax > this.page)
        || (this.list && this.list.nextToken)
      )
        && !this.error;
    }

    public getLowerPageBound(): number {
      return Math.max((this.page - 1) * this.itemsPerPage, 0);
    }

    public getUpperPageBound(): number {
      return Math.max(((this.page) * this.itemsPerPage) - 1, 0);
    }

    public async nextPage(): Promise<void> {
      this.error = undefined;

      // If page has not been viewed before, load the items before increased page number.
      if (this.page + 1 > this.pageMax) {
        let load = Promise.resolve(true);
        if (!this.list || this.list.items.length < this.getUpperPageBound() + this.itemsPerPage) {
          this.isLoading = true;

          load = this.loadItems(this.list.nextToken || 0, this.itemsPerPage)
            .then((list: ListResponse<any>) => {
              if (list.items.length > 0) {
                if (!this.list.items || this.list.items.length === 0) {
                  this.list.items = list.items;
                } else {
                  this.list.items = this.list.items.concat(list.items);
                }

                // TODO: nextToken -> SQL etc.
                this.list.nextToken = list.nextToken;
                // this.list.scannedCount = list.scannedCount ? list.scannedCount : undefined;
                return true;
              }

              this.list.nextToken = null;
              return false;
            });
        }

        try {
          const goToNextPage = await load;
          if (this.list.items.length > this.itemsPerPage * (this.page + 1)) {
            this.logger.error('Server returned more items than expected');
          } else if (this.list.items.length === this.itemsPerPage && !this.list.nextToken) {
            this.logger.warn(`Server returned exactly ${this.itemsPerPage} items`
              + ' per page but no nextToken; could indicate server-side nextToken issues');
          }

          if (goToNextPage) {
            ++this.page;
            this.pageMax = Math.max(this.pageMax, this.page);
          }
          this.isLoading = false;
        } catch (err: any) {
          this.logger.error(`Items for page ${this.page} could not be loaded`, err);
          const errMsg = err.message ?? (err.errors && err.errors.length ? err.errors[0].message : 'Unknown');

          this.error = errMsg;
          this.isLoading = false;
          ++this.page;
        }
      } else {
        // If page is equal or below pageMax, just display it (no loading needed).
        ++this.page;
      }
    }

    public async setPage(page: number): Promise<void> {
      await this.reset();

      const currPage = this.page;
      for (let i = currPage; i < page; i++) {
        // Call next page until the target page is reached.
        // eslint-disable-next-line no-await-in-loop
        await this.nextPage();
      }
    }

    private reset(): Promise<void> {
      this.page = 0;
      this.pageMax = 0;
      this.list.items = [];
      this.list.nextToken = null;
      this.list.scannedCount = undefined;
      return this.nextPage();
    }

    public resetList(): void {
      this.page = 0;
      this.pageMax = 0;
      this.list.items = [];
      this.list.nextToken = null;
      this.list.scannedCount = undefined;
    }

    public previousPage(): void {
      this.error = undefined;
      this.page = Math.max(1, this.page - 1);
    }
  };
}

interface PaginationMixinInterface {
  logger: NGXLogger;
  router: Router;
  list?: ListResponse<any>;
  loadItems: (start: number, limit: number) => Promise<ListResponse<any>>;
  updateNavParams: (page?: number) => void;
}

export { PaginationMixin, PaginationMixinInterface };
